import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  status: ['PENDENTE'],
  situacao: [],
  descricao: '',
  referencia: '',
  quantidade: '',
  unidade_medida: [],
  preco: '',
  requisitante: [],
  dtemissao: {
    i: null,
    f: null,
  },
  documento: '',
  filial: [],
  cegrupo_id: [],
  celinha_id: [],
  cemarca_id: [],
  historico: '',
  especificacao: '',
};

export const RequisicoesContext = createContext();

export const RequisicoesProvider = ({ children }) => {
  const [requisicoes, setRequisicoes] = useState({
    data: [],
    colunas: [],
    order: { field: 'dtemissao', sort: 'desc' },
    filter,
    page: 0,
    size: 10,
  });

  const [getLoading, setGetLoading] = useState(false);

  const getRequisicoes = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: requisicoes?.filter,
        page: requisicoes?.page,
        size: requisicoes?.size,
        order: requisicoes?.order,
        ...payload,
      });
      setRequisicoes((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <RequisicoesContext.Provider
      value={{
        requisicoes,
        getLoading,
        getRequisicoes,
      }}
    >
      {children}
    </RequisicoesContext.Provider>
  );
};
