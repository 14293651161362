import { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Pagination,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  Delete,
  InfoOutlined,
  MoreVert,
  Remove,
  Search,
  ShoppingCart,
  TrendingDown,
  TrendingUp,
} from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Controller, useForm } from 'react-hook-form';
import { useDialog } from '..';
import { summarizer } from 'utils/functions';
import localeText from 'utils/localeText';
import Input from 'components/Input';
import Button from 'components/Button';
import Blank from 'assets/blank.png';
import api from 'services/api';
import moment from 'moment';
import styles from './styles';

const CatalogoModal = ({ vendedor_id, onSubmit }) => {
  const defaultValues = {
    produto: '',
    vendedor_id,
    saldo: true,
    page: 0,
    size: 100,
  };
  const { closeDialog } = useDialog();
  const { control, watch, handleSubmit, setValue } = useForm({ defaultValues });
  const [count, setCount] = useState(0);
  const [produtos, setProdutos] = useState([]);
  const [carrinho, setCarrinho] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const loadData = async (values) => {
    try {
      setLoading(true);
      setProdutos([]);
      const { data } = await api.post(`/Comercial/Venda/ProdutoSaldo`, values);
      setProdutos(data?.rows || []);
      setCount(data?.count || 0);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onChangePage = (page) => {
    setValue('page', page);
    handleSubmit(loadData)();
  };

  const onAddItem = (item) => {
    setCarrinho((prev) =>
      Boolean(item?.quantidade > 0)
        ? prev?.some((s) => s?.id === item?.id)
          ? prev?.map((p) =>
              p?.id === item?.id
                ? { ...p, quantidade: item?.quantidade || 0 }
                : p
            )
          : [...prev, item]
        : prev?.filter((f) => f?.id !== item?.id)
    );
  };

  const onEditItem = ({ id, field, value }) => {
    setCarrinho((prev) =>
      prev?.map((p) => (p?.id === id ? { ...p, [field]: value || 0 } : p))
    );
  };

  const onConfirm = () => {
    setOpenModal(false);
    closeDialog();
    onSubmit(carrinho);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (Boolean(watch('produto'))) {
        setValue('page', 0);
        handleSubmit(loadData)();
      }
    }, 2000);
    if (Boolean(watch('produto'))) setLoading(true);
    return () => clearTimeout(delayDebounceFn);
  }, [watch('produto'), watch('saldo')]);

  const Item = ({ item }) => {
    const qtd = carrinho?.find((f) => f?.id === item?.id)?.quantidade || 0;
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState(qtd);
    const props = {
      item: true,
      xl: 2,
      lg: 2.4,
      md: 4,
      sm: 6,
      xs: 12,
      display: 'flex',
    };
    const color = item?.saldo > 0 ? 'success' : 'error';
    const label = item?.saldo + ' ' + (item?.unidade_medida || '');
    const icon = item?.saldo > 0 ? <TrendingUp /> : <TrendingDown />;
    const preco = (item?.preco || 0)?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
    const custo = (item?.custo || 0)?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
    const att_preco =
      Boolean(item?.atualizacao_preco) &&
      moment(item?.atualizacao_preco).isValid()
        ? `Preço atualizado em ${moment(item?.atualizacao_preco).format(
            'DD/MM/YYYY [às] HH:mm'
          )}`
        : null;
    const att_custo =
      Boolean(item?.atualizacao_custo) &&
      moment(item?.atualizacao_custo).isValid()
        ? `Custo atualizado em ${moment(item?.atualizacao_custo).format(
            'DD/MM/YYYY [às] HH:mm'
          )}`
        : null;

    const lines = [
      {
        label: 'Referência',
        value: item?.referencia,
      },
      {
        label: 'Marca',
        value: item?.marca,
      },
      {
        label: 'Localizador',
        value: item?.localizador,
      },
    ];

    return (
      <Grid {...props}>
        <Card sx={styles?.card}>
          <CardMedia sx={styles?.image} image={item?.url || Blank} />
          {Boolean(item?.especificacao) && (
            <>
              <Fab
                size="small"
                color="primary"
                sx={styles?.fab}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                <MoreVert />
              </Fab>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={styles?.anchorOrigin}
                transformOrigin={styles?.transformOrigin}
                slotProps={styles?.slotProps}
              >
                <Typography fontWeight="bold" textAlign="center">
                  Especificação
                </Typography>
                <Typography color="text.secondary" variant="caption">
                  {item?.especificacao}
                </Typography>
              </Popover>
            </>
          )}
          <CardContent sx={{ flex: 1 }}>
            <Typography variant="h6">{item?.descricao}</Typography>
            <Box display="flex" alignItems="center" my={1}>
              <Box display="flex" alignItems="center" flex={1}>
                <Typography variant="body1" fontWeight="bold" color="primary">
                  {preco}
                </Typography>
                {Boolean(att_preco) && (
                  <Tooltip sx={styles?.tooltip} title={att_preco}>
                    <InfoOutlined color="primary" fontSize="small" />
                  </Tooltip>
                )}
              </Box>
              <Chip
                icon={icon}
                label={label}
                color={color}
                size="small"
                variant="outlined"
              />
            </Box>
            <Box display="flex">
              <Typography
                flex={1}
                variant="caption"
                fontWeight="bold"
                color="text.secondary"
              >
                Custo:{' '}
              </Typography>
              <Box
                flex={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="caption" color="text.secondary">
                  {custo}
                </Typography>
                {Boolean(att_custo) && (
                  <Tooltip sx={styles?.tooltip} title={att_custo}>
                    <InfoOutlined color="action" fontSize="small" />
                  </Tooltip>
                )}
              </Box>
            </Box>
            {lines?.map(
              ({ label, value }, i) =>
                Boolean(value) && (
                  <Box key={i?.toString()} display="flex">
                    <Typography
                      flex={1}
                      variant="caption"
                      fontWeight="bold"
                      color="text.secondary"
                    >
                      {label}:{' '}
                    </Typography>
                    <Typography
                      flex={1}
                      variant="caption"
                      color="text.secondary"
                    >
                      {value}
                    </Typography>
                  </Box>
                )
            )}
          </CardContent>
          <CardActions>
            <Box
              flex={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <IconButton
                size="small"
                onClick={() => onAddItem({ ...item, quantidade: qtd - 1 })}
              >
                <Remove fontSize="small" />
              </IconButton>
              <TextField
                variant="standard"
                type="number"
                InputProps={styles?.inputProps}
                value={value}
                onChange={(v) =>
                  setValue(
                    isNaN(v?.target?.valueAsNumber)
                      ? 0
                      : v?.target?.valueAsNumber
                  )
                }
                onBlur={() => onAddItem({ ...item, quantidade: value })}
              />
              <IconButton
                size="small"
                onClick={() => onAddItem({ ...item, quantidade: qtd + 1 })}
              >
                <Add fontSize="small" />
              </IconButton>
            </Box>
          </CardActions>
        </Card>
      </Grid>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={8} lg={9} xl={10}>
        <Input
          name="produto"
          placeholder="Buscar..."
          control={control}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} textAlign="end">
        <Controller
          control={control}
          name="saldo"
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              label="Somente com saldo"
              control={<Checkbox checked={value} onChange={onChange} />}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        {loading && <LinearProgress />}
      </Grid>
      {!Boolean(produtos?.length) && !loading && (
        <Grid item xs={12} textAlign="center">
          <Typography variant="caption">
            Pesquise para carregar produtos
          </Typography>
        </Grid>
      )}
      {produtos?.map((item, index) => (
        <Item item={item} key={`${index}`} />
      ))}
      {Boolean(produtos?.length) && (
        <Grid item xs={12} display="flex" justifyContent="center">
          <Pagination
            count={Math.ceil(count / watch('size'))}
            color="primary"
            page={watch('page') + 1}
            onChange={(_, page) => onChangePage(page - 1)}
          />
        </Grid>
      )}
      <Box sx={styles?.fabContainer}>
        <Badge
          badgeContent={carrinho?.length}
          color="secondary"
          max={99}
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Fab
            color="primary"
            onClick={() => setOpenModal(true)}
            sx={styles?.badge}
          >
            <ShoppingCart />
          </Fab>
        </Badge>
      </Box>
      <Dialog open={openModal} PaperProps={{ sx: { minWidth: `80%` } }}>
        <DialogTitle>Produtos Selecionados</DialogTitle>
        <DialogContent>
          <DataGrid
            rows={carrinho}
            columns={[
              {
                field: 'descricao',
                headerName: 'Produto',
                flex: 1,
                sortable: false,
              },
              {
                field: 'referencia',
                headerName: 'Referência',
                width: 200,
                sortable: false,
              },
              {
                field: 'quantidade',
                headerName: 'Quantidade',
                type: 'number',
                width: 200,
                sortable: false,
                editable: true,
                renderCell: ({ row }) => (
                  <Typography variant="body2">
                    {row?.quantidade || 0} {row?.unidade_medida}
                  </Typography>
                ),
              },
              {
                field: 'preco',
                headerName: 'Preço Unitário',
                type: 'number',
                width: 200,
                sortable: false,
                valueFormatter: ({ value }) =>
                  (value || 0)?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }),
              },
              {
                field: 'subtotal',
                headerName: 'Subtotal',
                type: 'number',
                width: 200,
                sortable: false,
                valueGetter: ({ row }) =>
                  ((row?.preco || 0) * (row?.quantidade || 0))?.toLocaleString(
                    'pt-br',
                    { style: 'currency', currency: 'BRL' }
                  ),
              },
              {
                field: 'actions',
                type: 'actions',
                headerName: 'Ações',
                width: 100,
                getActions: ({ row }) => {
                  return [
                    <GridActionsCellItem
                      icon={<Delete />}
                      label="Excluir"
                      onClick={() =>
                        setCarrinho((prev) =>
                          prev?.filter((f) => f?.id !== row?.id)
                        )
                      }
                    />,
                  ];
                },
              },
            ]}
            autoHeight
            hideFooter
            density="compact"
            disableColumnMenu
            showCellRightBorder
            showColumnRightBorder
            disableSelectionOnClick
            localeText={localeText}
            onCellEditCommit={onEditItem}
          />
          <Typography variant="h6" textAlign="end" mt={2}>
            Total:{' '}
            {summarizer(
              carrinho?.map((c) => ({
                ...c,
                subtotal: (c?.preco || 0) * (c?.quantidade || 0),
              })),
              'subtotal'
            )?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setCarrinho([]);
              setOpenModal(false);
            }}
          >
            Limpar Carrinho
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenModal(false)}
          >
            Voltar
          </Button>
          <Button variant="contained" color="primary" onClick={onConfirm}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default CatalogoModal;
