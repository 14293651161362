import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { ProdutosContext } from 'contexts/ProdutosContext';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Header from 'components/Header';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Card from 'components/Card';

const FormaçãoDePreço = () => {
  const titulo = 'Formação De Preço';
  const navigate = useNavigate();
  const { state } = useLocation();
  const [rows, setRows] = useState([]);
  const { getDocumentoItens, documentoItens, getLoading } =
    useContext(DocumentosContext);
  const { postLoading, postProdutos } = useContext(ProdutosContext);
  const { control, handleSubmit } = useForm({ defaultValues: { markup: '' } });

  useEffect(() => {
    if (Boolean(state?.id)) {
      getDocumentoItens(state?.id);
    } else {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    setRows(
      documentoItens
        ?.filter((f) => Boolean(f?.produto_id))
        ?.map((item) => {
          let lojas = [];
          const trib2 =
            item?.DocumentoItemImpostos?.find((p) => p?.tributo_id === 2)
              ?.valor || 0;
          const trib14 =
            item?.DocumentoItemImpostos?.find((p) => p?.tributo_id === 14)
              ?.valor || 0;
          const trib20 =
            item?.DocumentoItemImpostos?.find((p) => p?.tributo_id === 20)
              ?.valor || 0;
          const icmsst_pc =
            item?.DocumentoItemImpostos?.find((p) => p?.tributo_id === 20)
              ?.percentual || 0;
          if (Boolean(item?.Produto?.ProdutoBlings?.length)) {
            lojas.push('BLING');
          }
          if (Boolean(item?.Produto?.ProdutoMls?.length)) {
            lojas.push('MERCADO LIVRE');
          }
          if (Boolean(item?.Produto?.ProdutoMagalus?.length)) {
            lojas.push('MAGAZINE LUIZA');
          }
          if (Boolean(item?.Produto?.ProdutoTrays?.length)) {
            lojas.push('TRAY');
          }
          if (Boolean(item?.Produto?.ProdutoWoos?.length)) {
            lojas.push('WOO');
          }
          return {
            id: item?.id,
            descricao: item.descricao,
            referencia: item?.referencia,
            quantidade: item?.quantidade,
            produto_id: item?.produto_id,
            unidade_medida: item?.UnidadeMedida?.sigla,
            preco: item?.sp_preco,
            custo: item?.sp_custo,
            difal: trib2 + trib14 + trib20,
            icmsst_pc,
            markup: Boolean(item?.sp_custo)
              ? ((item?.sp_preco || 0) / item?.sp_custo - 1) * 100
              : 0,
            lojas,
            ativo: item?.Produto?.ativo,
          };
        })
    );
  }, [documentoItens]);

  const onCellEditCommit = (e) => {
    const editedRows = rows?.map((c) => {
      if (c?.id === e?.id) {
        if (e?.field === 'preco') {
          const markup = Boolean(c?.custo)
            ? ((e?.value || 0) / c?.custo - 1) * 100
            : 0;
          return { ...c, [e?.field]: e?.value, markup };
        } else {
          return { ...c, [e?.field]: e?.value };
        }
      } else {
        return { ...c };
      }
    });
    setRows(editedRows);
  };

  const onRefresh = ({ markup }) => {
    setRows((prev) =>
      prev?.map((p) => ({
        ...p,
        markup,
        preco: (markup / 100) * p?.custo + p?.custo,
      }))
    );
  };

  const onSubmit = () => {
    const data = rows?.map((p) => ({
      Preco: {
        produto_id: p?.produto_id,
        documento_item_id: p?.id,
        icmsst_pc: p?.icmsst_pc,
        preco: p?.preco,
      },
    }));
    postProdutos({
      data,
      cb: () => getDocumentoItens(state?.id),
    });
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo={titulo} />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" alignItems="center">
            <InputMask name="markup" control={control} label="Markup" />
            <Button
              variant="outlined"
              color="primary"
              sx={{ ml: 1 }}
              onClick={handleSubmit(onRefresh)}
            >
              Atualizar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={rows}
              columns={[
                {
                  field: 'referencia',
                  headerName: 'Referência',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'descricao',
                  headerName: 'Produto',
                  flex: 3,
                  sortable: false,
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                  valueGetter: ({ row }) =>
                    `${row?.quantidade} ${row?.unidade_medida}`,
                },
                {
                  field: 'preco',
                  headerName: 'Preço (R$)',
                  type: 'number',
                  flex: 1,
                  editable: true,
                  sortable: false,
                },
                {
                  field: 'custo',
                  headerName: 'Custo',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                  valueGetter: ({ value }) =>
                    (value || 0)?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                },
                {
                  field: 'difal',
                  headerName: 'ST / DIFAL',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                  valueGetter: ({ value }) =>
                    (value || 0)?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                },
                {
                  field: 'icmsst_pc',
                  headerName: '% ST / DIFAL',
                  type: 'number',
                  flex: 1,
                  editable: true,
                  sortable: false,
                },
                {
                  field: 'markup',
                  headerName: 'Markup',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                  valueGetter: ({ value }) =>
                    Boolean(value) && `${(value || 0)?.toFixed(2)}%`,
                },
                {
                  field: 'lojas',
                  headerName: 'Lojas',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'ativo',
                  headerName: 'Ativo',
                  flex: 1,
                  sortable: false,
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
              onCellEditCommit={onCellEditCommit}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              loading={postLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default FormaçãoDePreço;
